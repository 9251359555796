<template>
  <div @keydown.esc="vcoConfig.handler()">
    <v-navigation-drawer
      absolute
      temporary
      right
      :value="open"
      app
      :width="800"
      stateless
      v-click-outside="vcoConfig"
      id="modgroupconfigpaneldrawer"
      v-bind:style="{ height: 'calc(100vh - 64px)', top: `${pageYOffset}px` }"
    >
      <v-form ref="form" :class="configClass" v-model="isFormValid">
        <v-layout column>
          <v-flex shrink>
            <v-container grid-list-xl class="pb-0" fluid v-if="groupClone.label">
              <v-layout row wrap>
                <v-layout class="display-flex justify-space-between" align-center row wrap>
                  <v-flex xs12>
                    <v-text-field
                      :error-messages="uniqueModifierMessage"
                      class="custom-validation"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <div class="display-flex align-center">
                      <v-text-field
                        v-model="groupClone.unique_name"
                        @blur="doneEdit"
                        @keydown.enter="doneEdit"
                        ref="labelinputGroupName"
                        :rules="rules"
                        v-on:keydown.enter.stop.prevent="() => {}"
                        @click.stop
                        label="Modifier Group Name"
                      />
                      <br />
                    </div>
                  </v-flex>
                  <v-flex xs9>
                    <div class="display-flex align-center">
                      <v-text-field
                        v-model="groupClone.label.en"
                        ref="labelinput"
                        :rules="rules"
                        v-on:keydown.enter.stop.prevent="() => {}"
                        label="Label In App"
                      />
                    </div>
                  </v-flex>
                  <v-flex xs3 class="sort_number">
                    <div>
                      <v-text-field
                        type="number"
                        :value="sortNumberProxy"
                        @input="sortNumberProxy = parseInt($event, 10) || undefined"
                        :rules="sortNumberRules"
                        v-on:keydown.enter.stop.prevent="() => {}"
                        label="Sequence On Ticket"
                        @keypress="blockDecimals"
                      />
                    </div>
                  </v-flex>
                  <v-flex>
                    <div class="Body-1-Black-Medium-Emphasis-Left">
                      How many items can the customer choose?
                    </div>
                    <v-layout class="size-line" row wrap align-baseline>
                      <v-flex xs4 style="margin-right: 24px;" shrink>
                        <v-select
                          :items="modifierTypes"
                          v-model="modifierValue"
                          style
                          @change="updateMinMax"
                        />
                      </v-flex>
                      <template v-if="modifierValue === 'Range'">
                        <v-flex xs2 shrink>
                          <v-text-field
                            v-model="minProxy"
                            :rules="rangeMinRules"
                            :error-messages="errorRangeMin"
                            type="number"
                            v-on:keydown.enter.stop.prevent="() => {}"
                          />
                        </v-flex>
                        <v-flex shrink style="margin: 0px 24px;">to</v-flex>
                        <v-flex xs2>
                          <v-text-field
                            v-model="maxProxy"
                            :rules="rangeMaxRules"
                            :error-messages="errorRangeMax"
                            type="number"
                            v-on:keydown.enter.stop.prevent="() => {}"
                          />
                        </v-flex>
                      </template>
                      <template v-if="modifierValue === 'Minimum'">
                        <v-flex xs2 shrink>
                          <v-text-field
                            v-model="minProxy"
                            :rules="requiredRule"
                            type="number"
                            v-on:keydown.enter.stop.prevent="() => {}"
                          />
                        </v-flex>
                      </template>
                      <template v-if="modifierValue === 'Maximum'">
                        <v-flex xs2 shrink>
                          <v-text-field
                            v-model="maxProxy"
                            :rules="requiredRule"
                            type="number"
                            v-on:keydown.enter.stop.prevent="() => {}"
                          />
                        </v-flex>
                      </template>
                      <template v-if="modifierValue === 'Exactly'">
                        <v-flex xs2 shrink>
                          <v-text-field
                            v-model="exactValue"
                            :rules="requiredRule"
                            type="number"
                            v-on:keydown.enter.stop.prevent="() => {}"
                          />
                        </v-flex>
                      </template>
                    </v-layout>

                    <v-text-field :error-messages="modifierMessage" class="custom-validation" />
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-container>
          </v-flex>
          <v-flex shrink>
            <v-layout column>
              <div style="padding-left:10px; padding-top:20px;">
                <v-flex xs12 class="display-flex">
                  <div class="H6-Secondary-Left flex xs9">Modifiers</div>
                  <div class="display-flex align-center justify-end text--secondary flex xs3 pr-0">
                    Item Enabled
                  </div>
                  <div
                    class="display-flex align-center justify-end text--secondary flex xs1 modifier_sort_number"
                  >
                    Sequence On Ticket
                  </div>
                </v-flex>
                <v-flex shrink>
                  <draggable
                    v-model="groupClone.items"
                    v-if="groupClone.items"
                    sort="false"
                    @end="updateSorting"
                    class="pa-0"
                  >
                    <modifier
                      v-for="(modifier, index) in groupClone.items"
                      :key="modifier.id || index"
                      :modifier.sync="modifier"
                      @modifier:groupaction="modifyGroupAction()"
                      @modifier:delete="deleteModifier(index)"
                      @modifier:copy="copyModifier(index)"
                      :taxOptions="taxOptions"
                    />
                  </draggable>
                  <v-layout>
                    <v-flex shrink>
                      <v-btn class="ml-0 pl-0" flat color="primary" @click="addModifier">
                        <v-icon>mdi-plus</v-icon>Add MODIFIER
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </div>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>
      <v-layout row justify-center class="cta-container">
        <div
          v-if="didFormReturnError"
          style="position: absolute; bottom: 8px; left: 16px; color: #ff5252"
        >
          Form has errors. Please check your values.
        </div>
        <v-flex shrink style="position: sticky; bottom: 0;">
          <v-btn color="primary" :loading="saving" @click="save" :disabled="!modified"
            >Apply Changes</v-btn
          >
          <v-btn flat @click="cancel" class="clickable">Cancel</v-btn>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapState, mapGetters } from 'vuex';
import isEqual from 'lodash/isEqual';
import draggable from 'vuedraggable';
import rules from '@/rules';
import modifier from './globalModifier';

export default {
  name: 'globalModGroupConfig',
  props: {
    group: Object,
    globalModGroups: Array,
    taxOptions: Array,
  },
  components: {
    modifier,
    draggable,
  },
  data() {
    return {
      open: false,
      groupClone: {},
      showActions: false,
      modifierTypes: ['Range', 'Minimum', 'Maximum', 'No limit', 'Exactly'],
      modifierValue: 'Range',
      editLabel: false,
      rules: [rules.required('Group name is required')],
      rangeMinRules: [rules.required('Required')],
      rangeMaxRules: [rules.required('Required')],
      requiredRule: [rules.required('Required')],
      sortNumberRules: [rules.min(1)],
      isFormValid: false,
      didFormReturnError: false,
      vcoConfig: {
        handler: async () => {
          if (this.open) {
            let shouldLeave = true;
            if (this.modified) {
              shouldLeave = await this.$confirm({
                title: 'You have unsaved changes',
                message: 'Are you sure you want to leave?',
                buttonTrueText: 'Yes',
                buttonFalseText: 'Cancel',
              });
            }
            if (shouldLeave) {
              this.cancel();
            }
          }
        },
        events: ['dblclick', 'click'],
        middleware(event, el) {
          if (el.id === 'modgroupconfigpaneldrawer') {
            if (event.x < window.innerWidth - 600) {
              return true;
            }
          }
          return false;
        },
        isActive: false,
      },
      saving: false,
      uniqueModifierMessage: '',
    };
  },
  computed: {
    ...mapGetters('adminPanel', ['isSiteOperator', 'isAdmin']),
    ...mapState('adminPanel', ['active_user_id']),
    ...mapState('menus', ['pageYOffset']),
    modified() {
      if (isEqual(this.groupClone, this.group)) {
        return false;
      }
      return true;
    },
    configClass() {
      return { saveable: true };
    },
    minProxy: {
      get() {
        return this.groupClone.min;
      },
      set(v) {
        this.groupClone = {
          ...this.groupClone,
          min: parseInt(v, 10),
        };
      },
    },
    maxProxy: {
      get() {
        return this.groupClone.max;
      },
      set(v) {
        this.groupClone = {
          ...this.groupClone,
          max: parseInt(v, 10),
        };
      },
    },
    sortNumberProxy: {
      get() {
        return this.groupClone.meta && this.groupClone.meta.sort_number;
      },
      set(v) {
        this.groupClone = {
          ...this.groupClone,
          meta: { ...this.groupClone.meta, sort_number: v },
        };
      },
    },
    exactValue: {
      get() {
        return this.maxProxy;
      },
      set(value) {
        this.groupClone = {
          ...this.groupClone,
          max: parseInt(value, 10),
          min: parseInt(value, 10),
        };
      },
    },

    errorRangeMin() {
      if (this.groupClone.min > this.groupClone.max) {
        return 'Must be less than max';
      }
      return '';
    },
    errorRangeMax() {
      if (this.groupClone.max < this.groupClone.min) {
        return 'Must be greater than min';
      }
      return '';
    },
    modifierMessage() {
      if (this.groupClone.min && this.groupClone.min > this.groupClone.items.length) {
        return `There must be at least ${this.groupClone.min} modifiers`;
      }

      if (this.groupClone.max && this.groupClone.max > this.groupClone.items.length) {
        return `There must be at least ${this.groupClone.max} modifiers`;
      }

      if (this.groupClone.items.length === 0) {
        return 'You must have at least one modifier';
      }

      return '';
    },
    hiddenColor() {
      if (this.groupClone.is.hidden) {
        return 'red';
      }
      return 'green';
    },
  },
  methods: {
    handleEscape(e) {
      if (e.keyCode === 27 && this.vcoConfig.handler) {
        this.vcoConfig.handler();
      }
    },
    async openConfig(isNew) {
      await this.$nextTick();
      this.vcoConfig.isActive = true;
      this.groupClone = cloneDeep(this.group);
      this.initGroup();
      this.didFormReturnError = false;
      this.open = true;
      if (isNew) {
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      }
    },
    async save() {
      this.saving = true;
      if (this.$refs.form.validate() && this.isFormValid) {
        if (this.groupClone.max === null) this.groupClone.max = undefined;
        if (this.groupClone.min === null) this.groupClone.min = undefined;
        this.$emit('update:globalModGroup', this.groupClone);
        this.$toast('Global modifier group has been updated');
        this.$refs.form.resetValidation();
        this.open = false;
      } else {
        this.didFormReturnError = true;
        this.$toast.error('Please correct the errors');
      }
      this.saving = false;
    },
    cancel() {
      this.groupClone = cloneDeep(this.group);
      if (!this.isFormValid) {
        this.$nextTick(() => {
          this.$refs.labelinputGroupName.reset();
        });
      }
      this.open = false;
      this.$emit('cancel-editing');
    },
    deleteModifier(index) {
      this.groupClone.items.splice(index, 1);
    },
    addModifier() {
      this.groupClone.items.push({
        is: {
          disabled: false,
        },
        meta: {
          taxes: [],
        },
        label: { en: '' },
        price: { amount: 0 },
      });
    },
    copyModifier(index) {
      const cloneItem = cloneDeep(this.groupClone.items[index]);
      cloneItem.label.en += ' copy';
      delete cloneItem.id;
      this.groupClone.items.push(cloneItem);
    },
    async updateMinMax(v) {
      switch (v) {
        case 'Exactly':
          this.groupClone.max = undefined;
          this.groupClone.min = this.groupClone.max;
          break;
        case 'No limit':
          this.groupClone.min = undefined;
          this.groupClone.max = undefined;
          break;
        case 'Minimum':
          this.groupClone.max = undefined;
          break;
        case 'Maximum':
          this.groupClone.min = undefined;
          break;
        default:
          break;
      }
      await this.$nextTick();
    },
    doneEdit() {
      this.uniqueModifierMessage = '';
      if (this.groupClone.unique_name && this.groupClone.unique_name.length > 0) {
        if (this.checkUniqueName(this.groupClone.unique_name)) {
          this.uniqueModifierMessage =
            'Modifier Group Name already exist in the company Global Modifiers.';
          this.startEdit();
          this.isFormValid = false;
          return;
        }
        this.editLabel = false;

        if (this.groupClone.items && this.groupClone.items.length === 0) {
          this.addModifier();
        }
      }
    },
    checkUniqueName(value) {
      return this.globalModGroups.find(
        (mg) => mg.unique_name && mg.unique_name.toLowerCase() === value.toLowerCase(),
      );
    },
    modifyGroupAction() {
      const items = this.groupClone && this.groupClone.items;
      const isGroupDisabled = !items.some((i) => !i.is.disabled);
      this.groupClone = {
        ...this.groupClone,
        is: {
          ...this.groupClone.is,
          disabled: isGroupDisabled,
        },
      };
    },

    toggleModifierGroup(e, property) {
      this.$set(this.groupClone.is, property, e);
      // set all the modifiers to the same value
      if (this.groupClone && this.groupClone.items && this.groupClone.items.length > 0) {
        this.groupClone.items.forEach((i) => {
          if (!i.is) i.is = {};
          this.$set(i.is, property, e);
        });
      }
    },
    async startEdit() {
      this.editLabel = true;
      this.$refs.labelinputGroupName.focus();
    },
    updateSorting(event) {
      this.setMenuSortNumber(event.newIndex, this.groupClone.items);
    },
    setMenuSortNumber(moved_record_index, records = []) {
      let last_record_with_sort_number_index = null;
      for (let i = records.length - 1; i >= 0; i -= 1) {
        const record = records[i];
        if (record && record.meta && record.meta.menu_sort_number) {
          last_record_with_sort_number_index = i;
          break;
        }
      }
      if (moved_record_index > last_record_with_sort_number_index) {
        last_record_with_sort_number_index = moved_record_index;
      }

      for (let i = 0; i <= last_record_with_sort_number_index; i += 1) {
        const record = records[i];
        this.$set(record, 'meta', { ...record.meta, menu_sort_number: i + 1 });
      }
    },
    initGroup() {
      // determine selection type
      let { min, max } = this.groupClone;
      if (min === undefined) min = null;
      if (max === undefined) max = null;

      if (min === null && max === null) {
        this.modifierValue = 'No limit';
      } else if (max === null) {
        this.modifierValue = 'Minimum';
      } else if (min === null) {
        this.modifierValue = 'Maximum';
      } else if (min === max) {
        this.modifierValue = 'Exactly';
      } else {
        this.modifierValue = 'Range';
      }
      if (!this.groupClone.unique_name) {
        this.groupClone.unique_name = this.groupClone.label && this.groupClone.label.en;
      }
    },
    blockDecimals(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode === 46) {
        event.preventDefault();
      }
    },
  },
  beforeMount() {
    if (!this.groupClone.is) {
      this.groupClone.is = {
        disabled: false,
      };
    }
  },
  watch: {
    isFormValid: {
      handler() {
        if (this.isFormValid) {
          this.didFormReturnError = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    document.addEventListener('keyup', this.handleEscape);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleEscape);
  },
};
</script>

<style scoped>
.cta-container {
  padding: 22px 0px 38px 0px;
  background: var(--v-light-base);
  z-index: 2;
}
.modifier-group {
  cursor: pointer;
  padding-right: 12px;
}

.modifier-group:hover {
  background-color: #f5f5f5;
}

.icon {
  margin: 10px 24px;
}

.modifiers-list {
  padding: 10px 0px;
  background-color: #fbfbfb;
}

.modifiers-list > * {
  padding: 0px 12px 0px 24px;
}

div >>> .size-line .v-text-field__slot input {
  padding-left: 24px;
}
.flex.sort_number {
  max-width: 3.5rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 1rem;
  margin-left: auto;
}
.flex.modifier_sort_number {
  max-width: 5rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 1rem;
  margin-left: 1rem;
}
</style>
