<template>
  <v-layout fill-height>
    <v-flex>
      <v-layout row wrap>
        <v-flex xs12>
          <v-layout>
            <v-flex style="min-width: 300px; max-width: 450px" xs3>
              <globalModNav ref="globalmod_nav" :title="navTitle" :loading="publishing" />
            </v-flex>
            <v-flex xs9>
              <v-container grid-list-xl>
                <v-layout row wrap justify-space-between>
                  <v-flex xs6>
                    <v-text-field label="Search global modifier groups" solo v-model="search" />
                  </v-flex>
                  <v-flex shrink>
                    <v-btn
                      color="primary"
                      @click="publishCompanyGlobalMods"
                      :loading="publishing"
                      :disabled="!canPublish"
                    >
                      <v-icon>mdi-upload</v-icon>publish
                    </v-btn>
                    <v-btn color="primary" @click="createGlobalModGroup">
                      <v-icon>mdi-plus</v-icon>new group
                    </v-btn>
                    <input
                      id="globalModsImportInput"
                      v-show="false"
                      type="file"
                      accept=".xlsx"
                      ref="globalModsImport"
                      @change="onGlobalModsImport"
                    />
                    <v-btn
                      color="info"
                      @click="$refs.globalModsImport.click()"
                      :disabled="!canImport"
                      :loading="importing"
                    >
                      <v-icon>mdi-file-upload</v-icon>Import
                    </v-btn>
                    <v-btn color="info" @click="exportCompanyGlobalMods" :disabled="!canExport">
                      <v-icon>mdi-file-download</v-icon>Export
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <v-container grid-list-xl>
            <v-layout row wrap fill-height>
              <v-flex xs12>
                <v-data-table
                  :headers="headers"
                  :items="globalModGroups"
                  class="modgrouptable"
                  :search="search"
                  ref="sortableTable"
                >
                  <template v-slot:headers="props" align-left>
                    <tr>
                      <th style="text-align: left">
                        Sequence On Ticket
                        <v-btn
                          flat
                          small
                          icon
                          @mousedown.stop
                          class="edit-btn"
                          @click.stop="toggleChitNumberEdits"
                          v-if="!showChitEditTextBoxes"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          flat
                          icon
                          class="edit-btn"
                          v-if="showChitEditTextBoxes"
                          @click="toggleChitNumberEdits"
                        >
                          <v-icon small>mdi-check</v-icon>
                        </v-btn>
                      </th>
                      <th
                        v-for="header in props.headers"
                        :key="header.text"
                        style="text-align: left"
                      >
                        {{ header.text }}
                      </th>
                    </tr>
                  </template>
                  <template v-slot:items="props">
                    <ModGroup-Row
                      @click.native="openGlobalModGroupConfig(props.item)"
                      :modGroup="props.item"
                      :key="props.item.id"
                      :showChitEditTextBoxes="showChitEditTextBoxes"
                      @modGroup:delete="deleteModGroup(props.item)"
                      @modGroup:copy="copyModGroup(props.item)"
                      @modGroup:groupaction="modifyGroupAction()"
                      class="sortableRow"
                    />
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-flex>
    <globalModGroup-Config
      :group="activeGlobalModGroup"
      @update:globalModGroup="updateGlobalModGroup"
      @cancel-editing="saveNewGlobalMod = null"
      :globalModGroups="globalModGroups"
      v-if="globalModGroups"
      ref="globalModGroupPanel"
      :taxOptions="taxOptions"
    />
    <import-errors-modal :errors="importErrors" v-model="showImportErrorsModal" />
  </v-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import saveFile from 'save-file';
import ExcelJS from 'exceljs';
import {
  createImportError,
  findDuplicatesByField,
  tryGetRowValue,
  parseRow,
  validateImportHeaders,
  getDuplicateErrorMessage,
} from '@/helpers/excelImport';
import { PRICE_RULE } from '@/constants';
import ModGroupRow from './globalModGroup';
import globalModNav from './globalModNav';
import globalModGroupConfig from './globalModGroupConfig';
import importErrorsModal from '../importErrorsModal';

export default {
  components: {
    globalModNav,
    ModGroupRow,
    globalModGroupConfig,
    importErrorsModal,
  },
  data: () => ({
    meta: {},
    skipRouteCheck: false,
    activeGlobalModGroup: {},
    globalModGroupHeaders: [
      { text: 'Modifier Group Name', value: 'unique_name', sortable: false },
      { text: 'Label in App', value: 'label.en', sortable: false },
      { text: 'Enabled', value: 'is.disabled', sortable: false },
    ],
    search: '',
    sortable: null,
    saveNewGlobalMod: null,
    globalModGroups: [],
    globalModGroupsOriginal: [],
    publishing: false,
    showChitEditTextBoxes: false,
    newModGroup: {
      label: { en: '' },
      unique_name: '',
      is: {
        disabled: false,
      },
      meta: {
        taxes: [],
        sort_number: 0,
      },
      company: '',
      items: [],
      min: 0,
      max: 0,
    },
    importing: false,
    importErrors: [],
    showImportErrorsModal: false,
    importModGroupValues: {
      'Modifier Group ID': { type: 'string', required: false },
      Label: { type: 'string', required: true },
      'Modifier Group Name': { type: 'string', required: true },
      'Modifier Group Enabled': { type: 'boolean', required: true },
      'Sequence On Ticket': { type: 'number', integerOnly: true, required: false },
      'Minimum Items': { type: 'number', integerOnly: true, required: false },
      'Maximum Items': { type: 'number', integerOnly: true, required: false },
    },
    importModValues: {
      'Modifier ID': { type: 'string', required: false },
      'Modifier Name': { type: 'string', required: true },
      'Modifier Enabled': { type: 'boolean', required: true },
      'Mod Sequence On Ticket': { type: 'number', integerOnly: true, required: false },
      PLU: { type: 'number', integerOnly: true, required: false },
      Price: { type: 'number', integerOnly: false, required: false },
      Calories: { type: 'number', integerOnly: true, required: false },
      'Tax Tags': { type: 'string', required: false },
    },
    taxOptions: ['Carbonated Beverage', 'Prepared', 'Sweetened Beverage'],
  }),
  computed: {
    ...mapState('sectors', ['active_sector', 'active_company']),
    canPublish() {
      return this.modified;
    },
    canImport() {
      return !this.modified;
    },
    canExport() {
      return !this.modified;
    },
    sectorName() {
      return (this.active_sector && this.active_sector.name) || '';
    },
    companyName() {
      return (this.active_company && this.active_company.name) || '';
    },
    companyId() {
      return this.$route.params.company_id;
    },
    navTitle() {
      if (this.activeLocation && this.activeBrand) {
        return `${this.activeBrand.name} @${this.activeLocation.name}`;
      }
      return '';
    },
    modified() {
      if (this.globalModGroups.length !== this.globalModGroupsOriginal.length) {
        return true;
      }
      for (let i = 0; i < this.globalModGroups.length; i += 1) {
        const modGroup = this.globalModGroups[i];
        const modGroupBackup = this.globalModGroupsOriginal.find((e) => e.id === modGroup.id);
        if (modGroup && modGroupBackup) {
          if (!isEqual(modGroup, modGroupBackup)) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    },
    headers() {
      return this.globalModGroupHeaders;
    },
  },
  methods: {
    ...mapActions('menus', [
      'fetchCompanyGlobalModGroups',
      'fetchGlobalModGroup',
      'deleteGlobalModGroup',
      'postGlobalModGroup',
      'putGlobalModGroup',
    ]),
    goToSector() {
      this.$router.push({
        name: 'menu-sector-info',
        params: {
          sector_id: this.$route.params.sector_id,
        },
      });
    },
    async getCompanyGlobalMods() {
      const { modifier_groups } = await this.fetchCompanyGlobalModGroups({
        id: this.companyId,
      });

      this.globalModGroupsOriginal = cloneDeep(modifier_groups);
      this.globalModGroups = cloneDeep(modifier_groups);
    },
    async addModGroup(modGroup) {
      if (modGroup.id) {
        delete modGroup.id;
      }
      await this.postGlobalModGroup({ ...modGroup, company: this.companyId });
      return true;
    },
    async publishCompanyGlobalMods() {
      this.publishing = true;
      const requests = [];
      const updatedIDs = this.globalModGroups.map((e) => e.id).filter(Boolean);

      const deletedGlobalModGroups = this.globalModGroupsOriginal
        .map((e) => e.id)
        .filter((e) => !updatedIDs.includes(e));
      const newGlobalModGroups = this.globalModGroups.filter((e) => !e.id);

      await Promise.all(
        this.globalModGroups.map(async (modGroup) => {
          if (!updatedIDs.includes(modGroup.id)) return;
          const modGroupOriginal = this.globalModGroupsOriginal.find(
            (group) => modGroup.id === group.id,
          );
          if (isEqual(modGroup, modGroupOriginal)) return;
          // updatedIDs.splice(updatedIDs.indexOf(modGroup.id), 1);
          if (!modGroup.items || modGroup.items.length === 0) {
            // this mod group items were not loaded
            const modGroupDetails = await this.getModGroupDetails(modGroup);
            modGroup.items = [...modGroupDetails.items];
          }
          requests.push(this.putGlobalModGroup(modGroup));
        }),
      );

      requests.push(...newGlobalModGroups.map((e) => this.addModGroup(e)));
      requests.push(...deletedGlobalModGroups.map((e) => this.deleteGlobalModGroup(e)));

      try {
        await Promise.all(requests);
      } catch (err) {
        console.error(err);
        this.$toast('Global Modifiers could not be published');
      }
      this.$toast('Global Modifier Groups successfully published');
      this.publishing = false;
      this.cleanUp();
      this.$store.commit('adminPanel/setLoading', false);
      this.getCompanyGlobalMods();
    },
    async exportCompanyGlobalMods() {
      const now = new Date();

      try {
        const { data } = await this.api.get(
          `/menu/modifier/group/company/${this.companyId}/export`,
        );
        await saveFile(
          data.file,
          `${this.companyName}-global-modifier-groups-${now.toISOString()}.${data.format}`,
        );
      } catch (err) {
        console.error(err);
        this.$toast.error('Error exporting modifier group.');
      }
    },
    async onGlobalModsImport(event) {
      const file = event.target.files.length > 0 && event.target.files[0];
      // allows input@change (this function) to trigger after editing and reuploading the same file
      this.$refs.globalModsImport.value = null;
      if (!file || !file.name) {
        this.$toast.error('Error uploading global modifiers file.');
        return;
      }
      this.importGlobalMods(file);
    },
    async importGlobalMods(file) {
      if (!file) return;

      this.importing = true;
      this.importErrors = []; // clear old import errors

      const reader = new FileReader();
      new Promise((resolve, reject) => {
        reader.readAsArrayBuffer(file);

        reader.onload = async () => {
          const buffer = reader.result;
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(buffer);

          // try parsing data from imported excel sheet
          const { parsedGlobalMods, parseErrors } = this.parseGlobalModsExcel(workbook);
          if (parseErrors.length > 0) {
            reject(parseErrors);
          }

          // check for duplicate values
          let duplicateErrors = this.checkGlobalModDuplicates(parsedGlobalMods);
          if (duplicateErrors.length > 0) {
            reject(duplicateErrors);
          }

          // try to merge existing global mods with imported global mods
          // eslint-disable-next-line prefer-const
          let { mergedModGroups, mergeErrors } = await this.mergeImportedModGroups(
            parsedGlobalMods,
          );
          if (mergeErrors.length > 0) {
            reject(mergeErrors);
          }

          // check after merge again for duplicate values
          duplicateErrors = this.checkGlobalModDuplicates(mergedModGroups);
          if (duplicateErrors.length > 0) {
            reject(duplicateErrors);
          }

          // verify that mods have correct values
          const verificationErrors = this.verifyGlobalMods(mergedModGroups);
          if (verificationErrors.length > 0) {
            reject(verificationErrors);
          }

          // remove excel sheet/row info
          mergedModGroups = this.stripGlobalModExcelInfo(mergedModGroups);

          resolve(mergedModGroups);
        };
      })
        .then((mergedModGroups) => {
          this.globalModGroups = mergedModGroups;
          this.$toast.success(
            'Global modifiers successfully imported locally. Please verify before publishing.',
          );
        })
        .catch((errors) => {
          this.importErrors = errors;
          this.showImportErrorsModal = true;
        })
        .finally(() => {
          this.importing = false;
        });
    },
    stripGlobalModExcelInfo(globalMods) {
      return globalMods.map((globalMod) => {
        delete globalMod.excelSheetName;
        delete globalMod.excelSheetRow;
        if (globalMod.items) {
          globalMod.items.forEach((item) => {
            delete item.excelSheetName;
            delete item.excelSheetRow;
          });
        }
        return globalMod;
      });
    },
    checkGlobalModDuplicates(globalMods) {
      // global mod ID
      const duplicateErrors = findDuplicatesByField(globalMods, 'id').map((duplicate) =>
        createImportError(
          duplicate.excelSheetName,
          duplicate.excelSheetRow,
          getDuplicateErrorMessage('Modifier Group', 'Modifier Group ID', duplicate),
          duplicate.unique_name || (duplicate.label && duplicate.label.en),
        ),
      );

      // global mod unique_name
      duplicateErrors.push(
        ...findDuplicatesByField(globalMods, 'unique_name').map((duplicate) =>
          createImportError(
            duplicate.excelSheetName,
            duplicate.excelSheetRow,
            getDuplicateErrorMessage('Modifier Group', 'Modifier Group Name', duplicate),
            duplicate.unique_name || (duplicate.label && duplicate.label.en),
          ),
        ),
      );

      // modifier ID
      const modifiers = globalMods.flatMap(({ items }) => items);
      duplicateErrors.push(
        ...findDuplicatesByField(modifiers, 'id').map((duplicate) =>
          createImportError(
            duplicate.excelSheetName,
            duplicate.excelSheetRow,
            getDuplicateErrorMessage('Modifier', 'Modifier ID', duplicate),
            duplicate.unique_name || (duplicate.label && duplicate.label.en),
          ),
        ),
      );

      return duplicateErrors;
    },
    verifyGlobalMods(modGroups) {
      const modGroupTests = [
        {
          test: (mg) =>
            mg.meta &&
            mg.meta.sort_number !== undefined &&
            mg.meta.sort_number !== null &&
            mg.meta.sort_number <= 0,
          message: () => 'Modifier group "Sequence On Ticket" must be greater than 0.',
        },
        {
          test: (mg) => mg.min !== undefined && mg.max === undefined && mg.min < 0,
          message: () =>
            // eslint-skip-line no-unused-vars
            'Modifier group "Minimum Items" must be 0 or more if set. For no-limit, leave empty.',
        },
        {
          test: (mg) => mg.min > mg.max,
          message: () =>
            'Modifier group item limit range invalid. For no limit, leave "Minimum Items" and "Maximum Items" empty, and make sure "Minimum Items" is less than "Maximum Items" if both are set.',
        },
        {
          test: (mg) => mg.min === undefined && mg.max !== undefined && mg.max < 0,
          message: () =>
            'Modifier group "Maximum Items" must be 0 or more if set. For no-limit, leave empty.',
        },
        {
          test: (mg) => Math.max(mg.min || 0, mg.max || 0) > ((mg.items && mg.items.length) || 0),
          message: (mg) => {
            const minReqMods = Math.max(mg.min || 0, mg.max || 0);
            return `Modifier groups must have enough modifiers to reach the minimum or maximum items, whichever is higher. Please add at least ${minReqMods} ${
              minReqMods === 1 ? 'modifier' : 'modifiers'
            } to this modifier group.`;
          },
        },
      ];

      const modTests = [
        {
          test: (mod) =>
            mod.price &&
            mod.price.amount &&
            (mod.price.amount < PRICE_RULE.min || mod.price.amount > PRICE_RULE.max),
          message: () =>
            `Modifier "Price" must be a number from ${PRICE_RULE.min} to ${PRICE_RULE.max}.`,
        },
        {
          test: (mod) =>
            mod.nutrition &&
            mod.nutrition.calories &&
            mod.nutrition.calories.amount &&
            mod.nutrition.calories.amount < 0,
          message: () => 'Modifier "Calories" must be 0 or higher.',
        },
        {
          test: (mod) =>
            mod.meta &&
            mod.meta.sort_number !== undefined &&
            mod.meta.sort_number !== null &&
            mod.meta.sort_number <= 0,
          message: () => 'Modifier "Mod Sequence On Ticket" must be greater than 0.',
        },
        {
          test: (mod) =>
            mod.meta &&
            mod.meta.taxes &&
            mod.meta.taxes.some((taxTag) => !this.taxOptions.includes(taxTag)),
          message: () =>
            `Modifier "Tax Tags" contains invalid entries. Valid tax tags are: "${this.taxOptions.join(
              '", "',
            )}"`,
        },
      ];

      return modGroups.reduce((errors, modGroup) => {
        // only validate modgroups that were imported or merged with an imported mod group
        if (modGroup.excelSheetName || modGroup.excelSheetRow) {
          modGroupTests.forEach((test) => {
            if (test.test(modGroup)) {
              errors.push(
                createImportError(
                  modGroup.excelSheetName,
                  modGroup.excelSheetRow,
                  test.message(modGroup),
                  modGroup.unique_name || (modGroup.label && modGroup.label.en),
                ),
              );
            }
          });
        }

        if (modGroup.items) {
          modGroup.items.forEach((mod) => {
            // only validate mods that were imported or merged with an imported mod
            if (mod.excelSheetName || mod.excelSheetRow) {
              modTests.forEach((test) => {
                if (test.test(mod)) {
                  errors.push(
                    createImportError(
                      mod.excelSheetName,
                      mod.excelSheetRow,
                      test.message(mod),
                      mod.label && mod.label.en,
                    ),
                  );
                }
              });
            }
          });
        }

        return errors;
      }, []);
    },
    async mergeImportedModGroups(importedModGroups) {
      const mergeErrors = [];

      const originalModGroups = cloneDeep(this.globalModGroups);

      const mergedModGroups = await Promise.all(
        importedModGroups.map(async (importedModGroup) => {
          const originalModGroup = originalModGroups.find((mg) => mg.id === importedModGroup.id);

          if (!originalModGroup && importedModGroup.id) {
            mergeErrors.push(
              createImportError(
                importedModGroup.excelSheetName,
                importedModGroup.excelSheetRow,
                'Imported modifier group has ID set, but no matching modifier group was found.',
                `Modifier Group: ${importedModGroup.unique_name ||
                  (importedModGroup.label && importedModGroup.label.en)}`,
              ),
            );
          } else if (originalModGroup) {
            const fullModGroup = await this.loadGlobalModGroupConfig(originalModGroup);

            importedModGroup.items = importedModGroup.items.map((importedItem) => {
              const originalItem = fullModGroup.items.find((item) => item.id === importedItem.id);

              if (!originalItem && importedItem.id) {
                mergeErrors.push(
                  createImportError(
                    importedItem.excelSheetName,
                    importedItem.excelSheetRow,
                    'Imported modifier has ID set, but no matching modifer was found.',
                    `Modifier: ${importedItem.unique_name ||
                      (importedItem.label && importedItem.label.en)}`,
                  ),
                );
              } else if (originalItem) {
                return merge({}, originalItem, importedItem);
              }
              return importedItem;
            });

            // add unmerge/unmodified modifiers back in
            originalModGroup.items.forEach((originalItem) => {
              const merged = importedModGroup.items.find(
                (importedItem) => importedItem.id === originalItem.id,
              );
              if (!merged) {
                importedModGroup.items.push(originalItem);
              }
            });

            return merge({}, originalModGroup, importedModGroup);
          }
          return importedModGroup;
        }),
      );

      // add unmerged/unmodified mod groups back in
      originalModGroups.forEach((originalModGroup) => {
        const merged = mergedModGroups.find(
          (mergedModGroup) => mergedModGroup.id === originalModGroup.id,
        );
        if (!merged) {
          mergedModGroups.push(originalModGroup);
        }
      });

      return { mergedModGroups, mergeErrors };
    },
    parseGlobalModsExcel(workbook) {
      const parseErrors = [];
      const globalMods = [];

      const requiredHeaders = ['Record Type'];
      requiredHeaders.push(...Object.keys(this.importModGroupValues));
      requiredHeaders.push(...Object.keys(this.importModValues));

      workbook.eachSheet((sheet) => {
        const headers = sheet.getRow(1); // first row is column headers
        let currentModGroup = null;

        const headerErrors = validateImportHeaders(requiredHeaders, headers, sheet.name);
        parseErrors.push(...headerErrors);
        if (headerErrors.length > 0) return; // don't continue if there are headers missing/invalid, will flood with errors

        sheet.eachRow({ includeEmpty: false }, (row, rowIndex) => {
          if (rowIndex === 1) return; // header row

          try {
            const recordType = tryGetRowValue('Record Type', headers, row);

            if (recordType === 'Modifier Group') {
              if (currentModGroup) {
                globalMods.push(currentModGroup); // next modifier group
                currentModGroup = null;
              }

              const { modGroup, errors } = this.parseModifierGroupExcelRow(
                headers,
                sheet.name,
                row,
              );
              parseErrors.push(...errors);

              modGroup.excelSheetRow = rowIndex;
              currentModGroup = modGroup;
            } else if (recordType === 'Modifier' && currentModGroup) {
              const { mod, errors } = this.parseModifierExcelRow(headers, sheet.name, row);

              parseErrors.push(...errors);

              mod.excelSheetRow = rowIndex;
              currentModGroup.items.push(mod);
            } else if (!['Modifier Group', 'Modifier'].includes(recordType)) {
              parseErrors.push(
                createImportError(
                  sheet.name,
                  rowIndex,
                  'Invalid "Record Type". Should be "Modifier Group" or "Modifier".',
                ),
              );
            }
          } catch (err) {
            parseErrors.push(
              createImportError(sheet.name, rowIndex, `Error trying to read "Record Type" value.`),
            );
          }
        });

        if (currentModGroup) globalMods.push(currentModGroup); // push last modifier group
      });

      return { parsedGlobalMods: globalMods, parseErrors };
    },
    parseModifierGroupExcelRow(headers, sheetName, row) {
      const { errors, parsedData } = parseRow(this.importModGroupValues, headers, sheetName, row);

      return {
        modGroup: {
          id: parsedData['Modifier Group ID'],
          label: {
            en: parsedData.Label,
          },
          unique_name: parsedData['Modifier Group Name'],
          is: {
            disabled: !parsedData['Modifier Group Enabled'],
          },
          meta: {
            taxes: [],
            sort_number: parsedData['Sequence On Ticket'],
          },
          min: parsedData['Minimum Items'],
          max: parsedData['Maximum Items'],
          items: [],
        },
        errors,
      };
    },
    parseModifierExcelRow(headers, sheetName, row) {
      const { errors, parsedData } = parseRow(this.importModValues, headers, sheetName, row);

      if (parsedData['Tax Tags'] !== undefined) {
        try {
          parsedData['Tax Tags'] = JSON.parse(parsedData['Tax Tags']);

          if (!Array.isArray(parsedData['Tax Tags'])) {
            throw new Error('"Tax Tags" is not an array.');
          } else if (!parsedData['Tax Tags'].every((e) => typeof e === 'string')) {
            throw new Error('"Tax Tags" is not an array of strings.');
          }
        } catch (e) {
          console.error(e);
          errors.push(
            createImportError(
              sheetName,
              row._number,
              'Error parsing "Tax Tags". Format should be a JSON array of strings. ex. ["Prepared","Sweetened Beverage"]',
              'Modifier',
            ),
          );
        }
      } else {
        parsedData['Tax Tags'] = [];
      }

      return {
        mod: {
          id: parsedData['Modifier ID'],
          label: {
            en: parsedData['Modifier Name'],
          },
          is: {
            disabled: !parsedData['Modifier Enabled'],
          },
          meta: {
            sort_number: parsedData['Mod Sequence On Ticket'],
            plu: parsedData.PLU,
            taxes: parsedData['Tax Tags'],
          },
          price: {
            amount: parsedData.Price,
          },
          nutrition: {
            calories: {
              amount: parsedData.Calories,
            },
          },
        },
        errors,
      };
    },
    updateGlobalModGroup(newGlobalModGroup) {
      this.activeGlobalModGroup = Object.assign(this.activeGlobalModGroup, newGlobalModGroup);
      if (this.saveNewGlobalMod) {
        this.saveNewGlobalMod(newGlobalModGroup);
        this.saveNewGlobalMod = null;
      }
    },
    createGlobalModGroup() {
      const globalModGroup = {
        label: { en: '' },
        unique_name: '',
        is: {
          disabled: false,
        },
        meta: {
          taxes: [],
          sort_number: null,
        },
        items: [
          {
            label: { en: '' },
            is: {
              disabled: false,
            },
            meta: { sort_number: null },
          },
        ],
        min: null,
        max: null,
      };
      this.openGlobalModGroupConfig(globalModGroup);

      // need this to add new item to table on save
      this.saveNewGlobalMod = (newGlobalModGroup) => {
        this.globalModGroups.push(newGlobalModGroup);
      };
    },
    async loadGlobalModGroupConfig(globalModGroup) {
      if (!globalModGroup.items || globalModGroup.items.length === 0) {
        const modGroupDetails = await this.getModGroupDetails(globalModGroup);

        if (!modGroupDetails.items || !Array.isArray(modGroupDetails.items)) {
          modGroupDetails.items = [];
        } else {
          globalModGroup.items = [...modGroupDetails.items];
        }
      }
      return globalModGroup;
    },
    async openGlobalModGroupConfig(globalModGroup) {
      // we need to get the items for this group if there are no itmes
      this.activeGlobalModGroup = await this.loadGlobalModGroupConfig(globalModGroup);
      this.$refs.globalModGroupPanel.openConfig(true);
    },
    async getModGroupDetails(globalModGroup) {
      if (!globalModGroup.id) return globalModGroup;
      const modGroupDetails = await this.fetchGlobalModGroup({ id: globalModGroup.id });
      return modGroupDetails;
    },
    toggleChitNumberEdits() {
      this.showChitEditTextBoxes = !this.showChitEditTextBoxes;
    },
    deleteModGroup(modGroup) {
      this.globalModGroups.splice(this.globalModGroups.indexOf(modGroup), 1);
    },
    async copyModGroup(modGroup) {
      const index = this.globalModGroups.indexOf(modGroup);
      if (!this.globalModGroups[index].items || this.globalModGroups[index].items.length < 1) {
        const modGroupDetails = await this.fetchGlobalModGroup({
          id: this.globalModGroups[index].id,
        });
        this.globalModGroups[index].items = [...modGroupDetails.items];
      }
      const { id, ...newGlobalMod } = cloneDeep(this.globalModGroups[index]);
      newGlobalMod.unique_name += ' copy';
      if (Array.isArray(newGlobalMod.items)) {
        newGlobalMod.items.forEach((item) => {
          delete item.id;
        });
      }
      this.globalModGroups.push(newGlobalMod);
    },
    modifyGroupAction() {
      const all_items_disabed =
        !this.globalModGroups.items ||
        this.globalModGroups.items.every((i) => !i || !i.is || i.is.disabled);
      if (all_items_disabed) {
        this.$set(this.globalModGroups.is, 'disabled', true);
      }
    },

    cleanUp() {
      this.$el.removeEventListener('click', this.onActivity);
      clearInterval(this.sessionInterval);
    },
  },
  mounted() {
    this.$store.commit('adminPanel/setLoading', true);
    this.getCompanyGlobalMods();
    this.$store.commit('adminPanel/setLoading', false);
  },
  watch: {
    active_sector: {
      handler(newValue, oldValue) {
        if (!newValue || newValue.id === (oldValue && oldValue.id)) return;
        const activeCompany = this.active_sector.companies.find((c) => c.id === this.companyId);
        this.$store.commit('sectors/setActiveCompany', activeCompany);
      },
      immediate: true,
    },
  },
};
</script>

<style>
.modgrouptable .v-datatable {
  background: transparent !important;
}
.modgrouptable .v-datatable__actions {
  background: transparent !important;
}
.saveable {
  overflow: scroll;
  height: calc(100% - 108px);
}
.flex.sort_number {
  max-width: 3.5rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 1rem;
  margin-left: auto;
}
div >>> .overlay-pointer {
  cursor: pointer;
}
div >>> .overlay-pointer * {
  pointer-events: none;
}
</style>
<style scoped>
.menu-nav {
  background-color: #f7f7f7;
  height: 100%;
}
.list-item-style {
  padding-left: 36px;
}
.list-item {
  padding: 8px;
  cursor: pointer;
}
.list-item-large {
  padding-top: 16px;
  padding-bottom: 16px;
}
.list-item:hover {
  background-color: #ececec;
}

.active {
  background-color: #e3e3e3;
}
.no-icon {
  padding-left: 36px;
}
.listspan {
  overflow: hidden;
}
</style>
