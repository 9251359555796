import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-nav" },
    [
      _c(
        VLayout,
        { attrs: { column: "", "fill-height": "", "align-left": "" } },
        [
          _c(
            VFlex,
            {
              staticStyle: { "min-width": "300px", "max-width": "450px" },
              attrs: { xs3: "" }
            },
            [
              _c(
                VBtn,
                {
                  staticClass: "nav-btn",
                  attrs: {
                    block: "",
                    flat: "",
                    disabled: _vm.loading,
                    loading: _vm.loading
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.goToBrand()
                    }
                  }
                },
                [
                  _c(VIcon, { attrs: { left: "" } }, [
                    _vm._v("mdi-chevron-left")
                  ]),
                  _vm._v(" " + _vm._s(_vm.sectorName) + "\n      ")
                ],
                1
              ),
              _c(VDivider)
            ],
            1
          ),
          _c(
            VFlex,
            {
              staticClass: "list-item-style",
              staticStyle: { "margin-top": "40px" },
              attrs: { shrink: "" }
            },
            [
              _c(
                "div",
                { staticClass: "Subtitle-2-Black-Medium-Emphasis-Left" },
                [_vm._v(_vm._s(_vm.title) + "(Global)")]
              )
            ]
          ),
          _c(
            VFlex,
            {
              staticClass: "list-item-style",
              staticStyle: { "margin-bottom": "32px" }
            },
            [
              _c("div", { staticClass: "H4-Secondary-Center" }, [
                _vm._v(_vm._s(_vm.companyName))
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }