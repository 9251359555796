import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    {
      staticClass: "modifier",
      attrs: { row: "", wrap: "", "align-center": "" },
      on: {
        mouseover: function($event) {
          _vm.showActions = true
        },
        mouseleave: function($event) {
          _vm.showActions = false
        },
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.disableEdit($event)
        }
      }
    },
    [
      _c(
        VFlex,
        { attrs: { xs7: "" } },
        [
          _c(
            VLayout,
            { attrs: { row: "", nowrap: "" } },
            [
              _c(
                VFlex,
                {
                  staticClass: "d-flex align-center",
                  staticStyle: {
                    "margin-right": "10px",
                    width: "10rem",
                    "max-width": "10rem"
                  },
                  on: { click: _vm.edit }
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.editable,
                          expression: "!editable"
                        }
                      ],
                      staticClass: "Body-1-Black-Medium-Emphasis-Left"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.modifier.label.en) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(VTextField, {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable,
                        expression: "editable"
                      }
                    ],
                    ref: "label",
                    attrs: { rules: _vm.labelRules, label: "Item Name" },
                    model: {
                      value: _vm.modifier.label.en,
                      callback: function($$v) {
                        _vm.$set(_vm.modifier.label, "en", $$v)
                      },
                      expression: "modifier.label.en"
                    }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                {
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.edit }
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.editable,
                          expression: "!editable"
                        }
                      ],
                      staticClass: "Body-1-Black-Medium-Emphasis-Left"
                    },
                    [_vm._v("+$" + _vm._s(_vm.price && _vm.price.toFixed(2)))]
                  ),
                  _c(VTextField, {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable,
                        expression: "editable"
                      }
                    ],
                    ref: "price",
                    attrs: {
                      label: "Price",
                      type: "number",
                      value: _vm.price,
                      rules: _vm.priceRules,
                      prefix: "+$",
                      "single-line": ""
                    },
                    on: {
                      input: function($event) {
                        _vm.price = parseFloat($event) || 0
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                {
                  staticStyle: { "margin-right": "10px", "max-width": "100px" },
                  on: { click: _vm.edit }
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.editable,
                          expression: "!editable"
                        }
                      ],
                      staticClass:
                        "Body-1-Black-Medium-Emphasis-Left span_overflow",
                      attrs: { title: "Calories:" + _vm.calories }
                    },
                    [_vm._v("Calories:" + _vm._s(_vm.calories))]
                  ),
                  _c(VTextField, {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable,
                        expression: "editable"
                      }
                    ],
                    ref: "calories",
                    attrs: {
                      label: "Calories",
                      type: "number",
                      rules: _vm.calorieRules
                    },
                    on: { keypress: _vm.blockDecimals },
                    model: {
                      value: _vm.calories,
                      callback: function($$v) {
                        _vm.calories = _vm._n($$v)
                      },
                      expression: "calories"
                    }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                {
                  staticStyle: { "max-width": "80px" },
                  on: { click: _vm.edit }
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.editable,
                          expression: "!editable"
                        }
                      ],
                      staticClass:
                        "Body-1-Black-Medium-Emphasis-Left span_overflow",
                      attrs: { title: "PLU:" + _vm.plu }
                    },
                    [_vm._v("PLU:" + _vm._s(_vm.plu))]
                  ),
                  _c(VTextField, {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable,
                        expression: "editable"
                      }
                    ],
                    ref: "plu",
                    attrs: { label: "PLU:" },
                    on: { click: _vm.edit, keypress: _vm.blockDecimals },
                    model: {
                      value: _vm.plu,
                      callback: function($$v) {
                        _vm.plu = $$v
                      },
                      expression: "plu"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        VFlex,
        { attrs: { shrink: "" } },
        [
          _vm.editable
            ? _c(
                VBtn,
                {
                  staticClass: "ma-0",
                  attrs: { flat: "", icon: "" },
                  on: { click: _vm.disableEdit }
                },
                [_c(VIcon, { attrs: { small: "" } }, [_vm._v("mdi-check")])],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(VSpacer),
      _c(
        VFlex,
        { staticClass: "display-flex align-center", attrs: { shrink: "" } },
        [
          _c(
            VBtn,
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showActions,
                  expression: "showActions"
                }
              ],
              staticClass: "ma-0",
              attrs: { flat: "", icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("modifier:copy")
                }
              }
            },
            [
              _c(VIcon, { attrs: { small: "" } }, [
                _vm._v("mdi-content-copy")
              ])
            ],
            1
          ),
          _c(
            VBtn,
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showActions,
                  expression: "showActions"
                }
              ],
              staticClass: "ma-0",
              attrs: { flat: "", icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("modifier:delete")
                }
              }
            },
            [_c(VIcon, { attrs: { small: "" } }, [_vm._v("mdi-delete")])],
            1
          ),
          _c("div", { staticClass: "mr-1" }),
          _c(VCheckbox, {
            staticClass: "pt-0 mt-0 mr-4 d-inline-block",
            attrs: {
              "hide-details": "",
              color: "secondary",
              "false-value": true,
              "true-value": false
            },
            on: {
              change: function($event) {
                return _vm.$emit("modifier:groupaction")
              }
            },
            nativeOn: {
              click: function($event) {
                $event.stopPropagation()
              }
            },
            model: {
              value: _vm.modifier.is.disabled,
              callback: function($$v) {
                _vm.$set(_vm.modifier.is, "disabled", $$v)
              },
              expression: "modifier.is.disabled"
            }
          }),
          _c(
            VFlex,
            { staticClass: "sort_number" },
            [
              _c(VTextField, {
                attrs: {
                  type: "number",
                  label: "",
                  rules: _vm.sortNumberRules
                },
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                    $event.preventDefault()
                    return (function() {})($event)
                  },
                  keypress: _vm.blockDecimals
                },
                model: {
                  value: _vm.sortNumberProxy,
                  callback: function($$v) {
                    _vm.sortNumberProxy = $$v
                  },
                  expression: "sortNumberProxy"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        VFlex,
        {
          staticStyle: { "margin-right": "10px" },
          attrs: { xs7: "" },
          on: { click: _vm.edit }
        },
        [
          _c("div", { staticClass: "taxable_price" }, [
            _vm._v("Taxable Price")
          ]),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.editable,
                  expression: "!editable"
                }
              ],
              staticClass: "Body-1-Black-Medium-Emphasis-Left"
            },
            [_vm._v("+$" + _vm._s(_vm.taxablePrice))]
          ),
          _c(VTextField, {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editable,
                expression: "editable"
              }
            ],
            ref: "taxablePrice",
            attrs: {
              label: "Taxable Price",
              type: "number",
              rules: _vm.taxablePriceRules,
              prefix: "+$",
              "single-line": "",
              disabled: !_vm.isTaxablePriceEnabled
            },
            model: {
              value: _vm.taxablePrice,
              callback: function($$v) {
                _vm.taxablePrice = $$v
              },
              expression: "taxablePrice"
            }
          })
        ],
        1
      ),
      _c(
        VFlex,
        {
          staticStyle: { "margin-top": "10px" },
          attrs: { xs7: "" },
          on: { click: _vm.edit }
        },
        [
          _c(VSelect, {
            attrs: {
              items: _vm.taxOptions,
              label: "Tax Tags",
              multiple: "",
              clearable: "",
              chips: ""
            },
            model: {
              value: _vm.modifier.meta.taxes,
              callback: function($$v) {
                _vm.$set(_vm.modifier.meta, "taxes", $$v)
              },
              expression: "modifier.meta.taxes"
            }
          }),
          _c(VTextField, {
            attrs: { label: "Tax Tag (pcc)", clearable: "" },
            model: {
              value: _vm.pccTaxTagProxy,
              callback: function($$v) {
                _vm.pccTaxTagProxy = $$v
              },
              expression: "pccTaxTagProxy"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }