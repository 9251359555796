<template>
  <div class="menu-nav">
    <v-layout column fill-height align-left>
      <v-flex style="min-width: 300px; max-width: 450px;" xs3>
        <v-btn
          block
          flat
          :disabled="loading"
          :loading="loading"
          class="nav-btn"
          @click.native="goToBrand()"
        >
          <v-icon left>mdi-chevron-left</v-icon> {{ sectorName }}
        </v-btn>
        <v-divider />
      </v-flex>
      <v-flex class="list-item-style" style="margin-top: 40px;" shrink>
        <div class="Subtitle-2-Black-Medium-Emphasis-Left">{{ title }}(Global)</div>
      </v-flex>
      <v-flex class="list-item-style" style="margin-bottom: 32px;">
        <div class="H4-Secondary-Center">{{ companyName }}</div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    active: Object,
    title: String,
    parentMenus: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('sectors', ['active_sector', 'active_company']),
    sectorName() {
      return (this.active_sector && this.active_sector.name) || '';
    },
    companyName() {
      return (this.active_company && this.active_company.name) || '';
    },
  },
  methods: {
    goToBrand() {
      this.$router.push({
        name: 'brand',
        params: {
          sector_id: this.active_company.sector,
          company_id: this.active_company.id,
        },
      });
    },
  },
};
</script>
<style scoped>
.menu-nav {
  background-color: #f7f7f7;
  height: 100%;
}
.list-item-style {
  padding-left: 36px;
}
.nav-btn {
  border-radius: 0;
  height: 60px;
  letter-spacing: unset !important;
  margin: 0;
  padding-left: 8px;
  text-transform: none;
}
>>> .v-btn__content {
  justify-content: start;
}

.menu-nav {
  background-color: #f7f7f7;
  height: 100%;
}
.list-item-style {
  padding-left: 36px;
}
.list-item {
  padding: 8px;
  cursor: pointer;
}
.list-item-large {
  padding-top: 16px;
  padding-bottom: 16px;
}
.list-item:hover {
  background-color: #ececec;
}

.active {
  background-color: #e3e3e3;
}
.no-icon {
  padding-left: 36px;
}
.listspan {
  overflow: hidden;
}
</style>
