import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { staticClass: "clickable" }, [
    _c(
      "td",
      { attrs: { nowrap: "" } },
      [
        _c(
          VLayout,
          { attrs: { "align-left": "" } },
          [
            !_vm.showChitEditTextBoxes
              ? _c(VFlex, [
                  _vm._v(
                    _vm._s(
                      (_vm.modGroup.meta && _vm.modGroup.meta.sort_number) || ""
                    )
                  )
                ])
              : _vm._e(),
            _vm.showChitEditTextBoxes
              ? _c(
                  VFlex,
                  { staticClass: "sort_number" },
                  [
                    _c(VTextField, {
                      attrs: {
                        type: "number",
                        min: "0",
                        rules: _vm.sortNumberRules
                      },
                      on: {
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          $event.stopPropagation()
                          $event.preventDefault()
                          return (function() {})($event)
                        }
                      },
                      nativeOn: {
                        click: function($event) {
                          $event.stopPropagation()
                        }
                      },
                      model: {
                        value: _vm.sortNumberProxy,
                        callback: function($$v) {
                          _vm.sortNumberProxy = _vm._n($$v)
                        },
                        expression: "sortNumberProxy"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(VSpacer)
          ],
          1
        )
      ],
      1
    ),
    _c(
      "td",
      { attrs: { nowrap: "" } },
      [
        _c(
          VLayout,
          { attrs: { "align-center": "" } },
          [
            _c(VFlex, [_vm._v(_vm._s(_vm.modGroup.unique_name))]),
            _c(VSpacer)
          ],
          1
        )
      ],
      1
    ),
    _c(
      "td",
      { attrs: { nowrap: "" } },
      [
        _c(
          VLayout,
          { attrs: { "align-center": "" } },
          [
            _c(VFlex, [_vm._v(_vm._s(_vm.modGroup.label.en))]),
            _c(VSpacer)
          ],
          1
        )
      ],
      1
    ),
    _c("td", { attrs: { nowrap: "" } }, [
      _vm.modGroupProxy.is
        ? _c(
            "span",
            [
              _c(VCheckbox, {
                attrs: {
                  "hide-details": "",
                  color: "secondary",
                  "false-value": true,
                  "true-value": false,
                  tabindex: "-1"
                },
                nativeOn: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                },
                model: {
                  value: _vm.modGroupProxy.is.disabled,
                  callback: function($$v) {
                    _vm.$set(_vm.modGroupProxy.is, "disabled", $$v)
                  },
                  expression: "modGroupProxy.is.disabled"
                }
              })
            ],
            1
          )
        : _vm._e()
    ]),
    _c(
      "td",
      {
        attrs: { nowrap: "" },
        on: {
          click: function($event) {
            $event.stopPropagation()
          }
        }
      },
      [
        _c(
          VBtn,
          {
            attrs: { small: "", flat: "", icon: "", tabindex: "-1" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.$emit("modGroup:copy")
              }
            }
          },
          [
            _c(VIcon, { attrs: { small: "" } }, [_vm._v("mdi-content-copy")])
          ],
          1
        ),
        _c(
          VBtn,
          {
            attrs: { small: "", flat: "", icon: "", tabindex: "-1" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.$emit("modGroup:delete")
              }
            }
          },
          [_c(VIcon, { attrs: { small: "" } }, [_vm._v("mdi-delete")])],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }