<template>
  <v-layout
    row
    wrap
    align-center
    class="modifier"
    @mouseover="showActions = true"
    @mouseleave="showActions = false"
    @keydown.enter="disableEdit"
  >
    <v-flex xs7>
      <v-layout row nowrap>
        <v-flex
          class="d-flex align-center"
          style="margin-right: 10px; width: 10rem; max-width: 10rem;"
          @click="edit"
        >
          <span class="Body-1-Black-Medium-Emphasis-Left" v-show="!editable">
            {{ modifier.label.en }}
          </span>
          <v-text-field
            v-model="modifier.label.en"
            ref="label"
            :rules="labelRules"
            v-show="editable"
            label="Item Name"
          ></v-text-field>
        </v-flex>
        <v-flex style="margin-right: 10px;" @click="edit">
          <span class="Body-1-Black-Medium-Emphasis-Left" v-show="!editable"
            >+${{ price && price.toFixed(2) }}</span
          >
          <v-text-field
            label="Price"
            type="number"
            :value="price"
            :rules="priceRules"
            @input="price = parseFloat($event) || 0"
            prefix="+$"
            ref="price"
            single-line
            v-show="editable"
          />
        </v-flex>
        <v-flex style="margin-right: 10px; max-width: 100px;" @click="edit">
          <span
            :title="'Calories:' + calories"
            class="Body-1-Black-Medium-Emphasis-Left span_overflow"
            v-show="!editable"
            >Calories:{{ calories }}</span
          >
          <v-text-field
            label="Calories"
            type="number"
            v-model.number="calories"
            :rules="calorieRules"
            v-show="editable"
            ref="calories"
            @keypress="blockDecimals"
          />
        </v-flex>
        <v-flex style="max-width: 80px;" @click="edit">
          <span
            :title="'PLU:' + plu"
            class="Body-1-Black-Medium-Emphasis-Left span_overflow"
            v-show="!editable"
            >PLU:{{ plu }}</span
          >
          <v-text-field
            label="PLU:"
            v-model="plu"
            v-show="editable"
            ref="plu"
            @click="edit"
            @keypress="blockDecimals"
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex shrink>
      <v-btn flat icon class="ma-0" v-if="editable" @click="disableEdit">
        <v-icon small>mdi-check</v-icon>
      </v-btn>
    </v-flex>
    <v-spacer />
    <v-flex shrink class="display-flex align-center">
      <v-btn flat icon class="ma-0" v-show="showActions" @click="$emit('modifier:copy')">
        <v-icon small>mdi-content-copy</v-icon>
      </v-btn>
      <v-btn flat icon class="ma-0" v-show="showActions" @click="$emit('modifier:delete')">
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
      <div class="mr-1"></div>
      <v-checkbox
        v-model="modifier.is.disabled"
        v-on:change="$emit('modifier:groupaction')"
        hide-details
        color="secondary"
        @click.native.stop
        :false-value="true"
        :true-value="false"
        class="pt-0 mt-0 mr-4 d-inline-block"
      />

      <v-flex class="sort_number">
        <v-text-field
          type="number"
          label
          v-model="sortNumberProxy"
          :rules="sortNumberRules"
          v-on:keydown.enter.stop.prevent="() => {}"
          @keypress="blockDecimals"
        />
      </v-flex>
    </v-flex>
    <v-flex xs7 style="margin-right: 10px; align-start" @click="edit">
      <div class="taxable_price">Taxable Price</div>
      <span class="Body-1-Black-Medium-Emphasis-Left" v-show="!editable">+${{ taxablePrice }}</span>
      <v-text-field
        label="Taxable Price"
        type="number"
        v-model="taxablePrice"
        :rules="taxablePriceRules"
        prefix="+$"
        ref="taxablePrice"
        single-line
        v-show="editable"
        :disabled="!isTaxablePriceEnabled"
      />
    </v-flex>
    <v-flex xs7 @click="edit" style="margin-top:10px;">
      <v-select
        :items="taxOptions"
        label="Tax Tags"
        v-model="modifier.meta.taxes"
        multiple
        clearable
        chips
      ></v-select>
      <v-text-field label="Tax Tag (pcc)" v-model="pccTaxTagProxy" clearable />
    </v-flex>
  </v-layout>
</template>

<script>
import get from 'lodash/get';
import { mapState, mapGetters, mapActions } from 'vuex';
import rules from '@/rules';
import { PRICE_RULE } from '@/constants';

export default {
  props: {
    modifier: Object,
    taxOptions: Array,
  },
  data: () => ({
    showActions: false,
    editLabel: false,
    labelRules: [rules.required('Label is required')],
    calorieRules: [rules.min(0)],
    sortNumberRules: [rules.min(1)],
    editPrice: false,
    editable: false,
    fetchingItems: false,
    items: [],
    taxoptions: ['Carbonated Beverage', 'Prepared', 'Sweetened Beverage'],
  }),

  computed: {
    ...mapGetters('adminPanel', ['isSiteOperator', 'isAdmin']),
    ...mapState('adminPanel', ['active_user_id']),
    plu: {
      get() {
        return get(this.modifier, 'meta.plu');
      },
      set(v) {
        if (!this.modifier.meta) {
          this.$set(this.modifier, 'meta', { plu: v });
        } else if (!this.modifier.meta.plu) {
          this.$set(this.modifier.meta, 'plu', v);
        } else {
          this.modifier.meta.plu = v;
        }
      },
    },
    modifierProxy: {
      get() {
        return this.modifier;
      },
      set(value) {
        this.$emit('update:modifier', value);
      },
    },
    sortNumberProxy: {
      get() {
        return this.modifierProxy.meta ? this.modifierProxy.meta.sort_number : '';
      },
      set(value) {
        this.$set(this.modifierProxy.meta, 'sort_number', parseInt(value, 10) || undefined);
      },
    },
    pccTaxTagProxy: {
      get() {
        return this.modifierProxy.meta?.tax?.tax_tag_code || '';
      },
      set(value) {
        this.$set(this.modifierProxy.meta, 'tax', { tax_tag_code: value });
      },
    },
    isModifierValid: {
      get() {
        const isLabelValid = (this.$refs.label && this.$refs.label.valid) || false;
        const isPriceValid = (this.$refs.price && this.$refs.price.valid) || false;
        const isCaloriesValid = (this.$refs.calories && this.$refs.calories.valid) || false;
        const isPluValid = !this.$refs.plu || this.$refs.plu.valid || false;
        return isLabelValid && isPriceValid && isCaloriesValid && isPluValid;
      },
    },
    calories: {
      get() {
        return (
          this.modifier.nutrition &&
          this.modifier.nutrition.calories &&
          this.modifier.nutrition.calories.amount
        );
      },
      set(value) {
        value = Number.isNaN(parseInt(value, 10)) ? undefined : parseInt(value, 10);
        if (
          this.modifier.nutrition &&
          this.modifier.nutrition.calories &&
          this.modifier.nutrition.calories.amount
        ) {
          this.modifierProxy.nutrition.calories.amount = value;
        } else {
          this.$set(this.modifierProxy, 'nutrition', {
            calories: { amount: value },
          });
        }
      },
    },
    price: {
      get() {
        if (this.modifierProxy.price && this.modifierProxy.price.amount) {
          return this.modifierProxy.price.amount;
        }
        return '';
      },
      set(value) {
        if (value === '') {
          this.$delete(this.modifierProxy, 'price');
        } else if (!this.modifierProxy.price) {
          this.$set(this.modifierProxy, 'price', { amount: value });
        } else {
          this.modifierProxy.price.amount = value;
        }
      },
    },
    taxablePrice: {
      get() {
        if (
          this.modifierProxy.meta &&
          this.modifierProxy.meta.taxable_price &&
          this.modifierProxy.meta.taxable_price.amount
        ) {
          return this.modifierProxy.meta.taxable_price.amount;
        }
        return '';
      },
      set(value) {
        if (value === '') {
          // if (this.modifierProxy.meta && this.modifierProxy.meta.taxable_price)
          this.$delete(this.modifierProxy.meta, 'taxable_price');
        } else if (!this.modifierProxy.meta.taxable_price) {
          this.$set(this.modifierProxy.meta, 'taxable_price', { amount: value });
        } else {
          this.modifierProxy.meta.taxable_price.amount = value;
        }
      },
    },
    isTaxablePriceEnabled() {
      // 'Carbonated Beverage', 'Prepared', 'Sweetened Beverage'
      if (!this.isModifierCarbonatedAndSweetened) {
        this.$set(this, 'taxablePrice');
      }
      return this.isModifierCarbonatedAndSweetened;
    },
    isModifierCarbonatedAndSweetened() {
      return (
        this.modifierProxy.meta.taxes &&
        this.modifierProxy.meta.taxes.indexOf('Carbonated Beverage') >= 0 &&
        this.modifierProxy.meta.taxes.indexOf('Sweetened Beverage') >= 0
      );
    },
    taxablePriceRules() {
      const required = (v) => {
        if (!this.isModifierCarbonatedAndSweetened) {
          return true;
        }
        if (v === '') return 'Taxable Price cannot be empty.';

        return !!parseFloat(v) || 'Taxable Price cannot be 0.';
      };
      return [required, rules.min(this.price, 'Taxable Price cannot be less than Price.')];
    },
    priceRules() {
      const rulesForPrice = [rules.min(PRICE_RULE.min), rules.max(PRICE_RULE.max)];
      if (this.isModifierCarbonatedAndSweetened) {
        rulesForPrice.push(rules.max(this.taxablePrice, 'Price must be less than Taxable Price'));
      }
      return rulesForPrice;
    },

    hiddenColor() {
      if (this.modifier.is.hidden) {
        return 'red';
      }
      return 'green';
    },
  },
  async created() {
    await this.$nextTick();
    if (!this.isModifierValid) {
      this.editable = true;
      this.$emit('modifier:invalid');
    }
  },
  beforeMount() {
    if (!this.modifier.is) {
      this.modifier.is = {
        disabled: false,
      };
    }
    if (!this.modifier.meta) {
      this.modifier.meta = {};
    }
  },
  methods: {
    ...mapActions('menus', ['getMenuItems']),
    edit() {
      this.editable = true;
    },
    disableEdit() {
      if (this.isModifierValid) {
        this.editable = false;
      }
    },
    blockDecimals(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode === 46) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
.modifier {
  padding: 0px 12px 0px 24px;
  min-height: 46px;
}
.modifier:hover {
  background-color: #ececec;
}
.editable:hover {
  cursor: text;
  text-decoration: underline;
}
div >>> .v-text-field__prefix {
  min-width: 28px;
}
.flex.sort_number {
  max-width: 3.5rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 1rem;
  margin-left: auto;
}
.span_overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
  display: inline-block;
}
.taxable_price {
  color: gray;
}
</style>
