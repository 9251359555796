import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { "fill-height": "" } },
    [
      _c(
        VFlex,
        [
          _c(
            VLayout,
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c(
                    VLayout,
                    [
                      _c(
                        VFlex,
                        {
                          staticStyle: {
                            "min-width": "300px",
                            "max-width": "450px"
                          },
                          attrs: { xs3: "" }
                        },
                        [
                          _c("globalModNav", {
                            ref: "globalmod_nav",
                            attrs: {
                              title: _vm.navTitle,
                              loading: _vm.publishing
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        VFlex,
                        { attrs: { xs9: "" } },
                        [
                          _c(
                            VContainer,
                            { attrs: { "grid-list-xl": "" } },
                            [
                              _c(
                                VLayout,
                                {
                                  attrs: {
                                    row: "",
                                    wrap: "",
                                    "justify-space-between": ""
                                  }
                                },
                                [
                                  _c(
                                    VFlex,
                                    { attrs: { xs6: "" } },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          label:
                                            "Search global modifier groups",
                                          solo: ""
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VFlex,
                                    { attrs: { shrink: "" } },
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          attrs: {
                                            color: "primary",
                                            loading: _vm.publishing,
                                            disabled: !_vm.canPublish
                                          },
                                          on: {
                                            click: _vm.publishCompanyGlobalMods
                                          }
                                        },
                                        [
                                          _c(VIcon, [_vm._v("mdi-upload")]),
                                          _vm._v("publish\n                  ")
                                        ],
                                        1
                                      ),
                                      _c(
                                        VBtn,
                                        {
                                          attrs: { color: "primary" },
                                          on: {
                                            click: _vm.createGlobalModGroup
                                          }
                                        },
                                        [
                                          _c(VIcon, [_vm._v("mdi-plus")]),
                                          _vm._v(
                                            "new group\n                  "
                                          )
                                        ],
                                        1
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: false,
                                            expression: "false"
                                          }
                                        ],
                                        ref: "globalModsImport",
                                        attrs: {
                                          id: "globalModsImportInput",
                                          type: "file",
                                          accept: ".xlsx"
                                        },
                                        on: { change: _vm.onGlobalModsImport }
                                      }),
                                      _c(
                                        VBtn,
                                        {
                                          attrs: {
                                            color: "info",
                                            disabled: !_vm.canImport,
                                            loading: _vm.importing
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$refs.globalModsImport.click()
                                            }
                                          }
                                        },
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-file-upload")
                                          ]),
                                          _vm._v("Import\n                  ")
                                        ],
                                        1
                                      ),
                                      _c(
                                        VBtn,
                                        {
                                          attrs: {
                                            color: "info",
                                            disabled: !_vm.canExport
                                          },
                                          on: {
                                            click: _vm.exportCompanyGlobalMods
                                          }
                                        },
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-file-download")
                                          ]),
                                          _vm._v("Export\n                  ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c(
                    VContainer,
                    { attrs: { "grid-list-xl": "" } },
                    [
                      _c(
                        VLayout,
                        { attrs: { row: "", wrap: "", "fill-height": "" } },
                        [
                          _c(
                            VFlex,
                            { attrs: { xs12: "" } },
                            [
                              _c(VDataTable, {
                                ref: "sortableTable",
                                staticClass: "modgrouptable",
                                attrs: {
                                  headers: _vm.headers,
                                  items: _vm.globalModGroups,
                                  search: _vm.search
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "headers",
                                    fn: function(props) {
                                      return [
                                        _c(
                                          "tr",
                                          [
                                            _c(
                                              "th",
                                              {
                                                staticStyle: {
                                                  "text-align": "left"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      Sequence On Ticket\n                      "
                                                ),
                                                !_vm.showChitEditTextBoxes
                                                  ? _c(
                                                      VBtn,
                                                      {
                                                        staticClass: "edit-btn",
                                                        attrs: {
                                                          flat: "",
                                                          small: "",
                                                          icon: ""
                                                        },
                                                        on: {
                                                          mousedown: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                          },
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.toggleChitNumberEdits(
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(VIcon, [
                                                          _vm._v("mdi-pencil")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.showChitEditTextBoxes
                                                  ? _c(
                                                      VBtn,
                                                      {
                                                        staticClass: "edit-btn",
                                                        attrs: {
                                                          flat: "",
                                                          icon: ""
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.toggleChitNumberEdits
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          VIcon,
                                                          {
                                                            attrs: { small: "" }
                                                          },
                                                          [_vm._v("mdi-check")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            ),
                                            _vm._l(props.headers, function(
                                              header
                                            ) {
                                              return _c(
                                                "th",
                                                {
                                                  key: header.text,
                                                  staticStyle: {
                                                    "text-align": "left"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(header.text) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "items",
                                    fn: function(props) {
                                      return [
                                        _c("ModGroup-Row", {
                                          key: props.item.id,
                                          staticClass: "sortableRow",
                                          attrs: {
                                            modGroup: props.item,
                                            showChitEditTextBoxes:
                                              _vm.showChitEditTextBoxes
                                          },
                                          on: {
                                            "modGroup:delete": function(
                                              $event
                                            ) {
                                              return _vm.deleteModGroup(
                                                props.item
                                              )
                                            },
                                            "modGroup:copy": function($event) {
                                              return _vm.copyModGroup(
                                                props.item
                                              )
                                            },
                                            "modGroup:groupaction": function(
                                              $event
                                            ) {
                                              return _vm.modifyGroupAction()
                                            }
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.openGlobalModGroupConfig(
                                                props.item
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.globalModGroups
        ? _c("globalModGroup-Config", {
            ref: "globalModGroupPanel",
            attrs: {
              group: _vm.activeGlobalModGroup,
              globalModGroups: _vm.globalModGroups,
              taxOptions: _vm.taxOptions
            },
            on: {
              "update:globalModGroup": _vm.updateGlobalModGroup,
              "cancel-editing": function($event) {
                _vm.saveNewGlobalMod = null
              }
            }
          })
        : _vm._e(),
      _c("import-errors-modal", {
        attrs: { errors: _vm.importErrors },
        model: {
          value: _vm.showImportErrorsModal,
          callback: function($$v) {
            _vm.showImportErrorsModal = $$v
          },
          expression: "showImportErrorsModal"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }