<template>
  <tr class="clickable">
    <td nowrap>
      <v-layout align-left>
        <v-flex v-if="!showChitEditTextBoxes">{{
          (modGroup.meta && modGroup.meta.sort_number) || ''
        }}</v-flex>
        <v-flex class="sort_number" v-if="showChitEditTextBoxes">
          <v-text-field
            type="number"
            min="0"
            v-model.number="sortNumberProxy"
            :rules="sortNumberRules"
            v-on:keydown.enter.stop.prevent="() => {}"
            @click.native.stop
          />
        </v-flex>
        <v-spacer />
      </v-layout>
    </td>
    <td nowrap>
      <v-layout align-center>
        <v-flex>{{ modGroup.unique_name }}</v-flex>
        <v-spacer />
      </v-layout>
    </td>
    <td nowrap>
      <v-layout align-center>
        <v-flex>{{ modGroup.label.en }}</v-flex>
        <v-spacer />
      </v-layout>
    </td>
    <td nowrap>
      <span v-if="modGroupProxy.is">
        <v-checkbox
          v-model="modGroupProxy.is.disabled"
          hide-details
          color="secondary"
          @click.native.stop
          :false-value="true"
          :true-value="false"
          tabindex="-1"
        />
      </span>
    </td>
    <td @click.stop nowrap>
      <v-btn small flat icon @click.stop="$emit('modGroup:copy')" tabindex="-1">
        <v-icon small>mdi-content-copy</v-icon>
      </v-btn>
      <v-btn small flat icon @click.stop="$emit('modGroup:delete')" tabindex="-1">
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import rules from '@/rules';

export default {
  props: {
    modGroup: Object,
    showChitEditTextBoxes: Boolean,
  },
  data: () => ({
    sortNumberRules: [rules.min(1)],
  }),
  computed: {
    ...mapGetters('adminPanel', ['isSiteOperator']),
    ...mapState('adminPanel', ['active_user_id']),
    modGroupProxy: {
      get() {
        return this.modGroup;
      },
      set(v) {
        this.$emit('update:modGroup', v);
      },
    },
    sortNumberProxy: {
      get() {
        return this.modGroupProxy.meta.sort_number;
      },
      set(value) {
        this.$set(this.modGroupProxy.meta, 'sort_number', parseInt(value, 10) || undefined);
      },
    },
  },
};
</script>

<style>
.v-icon .mdi .mdi-eye .theme--light {
  color: green;
}
.flex.sort_number {
  max-width: 11rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 1rem;
  margin-left: auto;
}
</style>
